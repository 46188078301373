import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/simplebar-react/dist/simplebar.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-lazy-load-image-component/src/effects/blur.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/branch-app/node_modules/next/font/google/target.css?{\"path\":\"../../packages/ui/theme/typography.js\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/branch-app/node_modules/next/font/google/target.css?{\"path\":\"../../packages/ui/theme/typography.js\",\"import\":\"Barlow\",\"arguments\":[{\"weight\":[\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"secondaryFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/components/animate/motion-lazy.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/components/progress-bar/progress-bar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/components/progress-bar/styles.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSettingsContext"] */ "/vercel/path0/packages/ui/components/settings/context/settings-context.js");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/vercel/path0/packages/ui/components/settings/context/settings-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/components/settings/drawer/settings-drawer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/components/snackbar/snackbar-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/locales/localization-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/theme/index.js");
